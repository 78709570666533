import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function MeetingIcon({
  width = 22,
  height = 22,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#fffff";
  return (
    <SVGContainer {...restProps}>
      <svg
        fill='white'
        height={height}
        width={width}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M102.4,0C60.049,0,25.6,34.449,25.6,76.8c0,42.351,34.449,76.8,76.8,76.8c42.351,0,76.8-34.449,76.8-76.8
			C179.2,34.449,144.751,0,102.4,0z M102.4,128c-28.279,0-51.2-22.921-51.2-51.2s22.921-51.2,51.2-51.2s51.2,22.921,51.2,51.2
			S130.68,128,102.4,128z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M102.4,384h-64c-7.049,0-12.8-5.751-12.8-12.8V256c0-7.074-5.726-12.8-12.8-12.8C5.726,243.2,0,248.926,0,256v115.2
			c0,21.171,17.229,38.4,38.4,38.4h12.8v76.8H38.4c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h51.2
			c7.074,0,12.8-5.726,12.8-12.8c0-7.074-5.726-12.8-12.8-12.8H76.8v-76.8h25.6c10.3,0,12.646,7.023,12.8,12.928
			c0.077,6.997,5.777,12.672,12.8,12.672c7.074,0,12.8-5.726,12.8-12.8C140.8,406.972,130.577,384,102.4,384z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M409.6,0c-42.351,0-76.8,34.449-76.8,76.8c0,42.351,34.449,76.8,76.8,76.8s76.8-34.449,76.8-76.8
			C486.4,34.449,451.951,0,409.6,0z M409.6,128c-28.279,0-51.2-22.921-51.2-51.2s22.921-51.2,51.2-51.2
			c28.279,0,51.2,22.921,51.2,51.2S437.879,128,409.6,128z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M499.2,243.2c-7.074,0-12.8,5.726-12.8,12.8v115.2c0,7.049-5.751,12.8-12.8,12.8h-64c-28.177,0-38.4,22.972-38.4,38.4
			c0,7.074,5.726,12.8,12.8,12.8c7.023,0,12.723-5.675,12.8-12.672c0.154-5.905,2.5-12.928,12.8-12.928h25.6v76.8h-12.8
			c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8c0-7.074-5.726-12.8-12.8-12.8
			h-12.8v-76.8h12.8c21.171,0,38.4-17.229,38.4-38.4V256C512,248.926,506.274,243.2,499.2,243.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M409.6,179.2c-28.228,0-51.2,22.972-51.2,51.2v58.351l-38.4,12.8V281.6h12.8c7.074,0,12.8-5.726,12.8-12.8
			c0-7.074-5.726-12.8-12.8-12.8H179.2c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8H192v19.951l-38.4-12.8V230.4
			c0-28.228-22.972-51.2-51.2-51.2s-51.2,22.972-51.2,51.2v102.4c0,11.75,7.996,21.99,19.388,24.832l86.682,21.675l22.426,112.119
			C182.093,503.39,192.597,512,204.8,512h102.4c12.203,0,22.707-8.61,25.105-20.574l22.426-112.119l86.682-21.675
			C452.804,354.79,460.8,344.55,460.8,332.8V230.4C460.8,202.172,437.828,179.2,409.6,179.2z M192,422.4l-12.8-64L76.8,332.8V230.4
			c0-14.14,11.46-25.6,25.6-25.6c14.14,0,25.6,11.46,25.6,25.6v76.8l64,21.333V422.4z M294.4,310.835V409.6v76.8h-76.8v-76.8
			v-98.765V281.6h76.8V310.835z M435.2,332.8l-102.4,25.6l-12.8,64v-93.867l64-21.333v-76.8c0-14.14,11.46-25.6,25.6-25.6
			c14.14,0,25.6,11.46,25.6,25.6V332.8z"
            />
          </g>
        </g>
      </svg>
    </SVGContainer>
  );
}

export default MeetingIcon;
