import { useEffect, useState } from "react";
import { MuiMenuItem, MuiTextField } from ".";
import { TextFieldProps, MenuList, Backdrop } from "@mui/material";
import { styled } from "@mui/material";

const StyledWarapper = styled("div")(({ theme }) => ({
  position: "relative",
  // zIndex: theme.zIndex.appBar,

  ".list-part": {
    position: "absolute",
    top: "calc(100% + 0.5rem)",
    left: "0",
    boxShadow: "0 0 5px #ccc",
    borderRadius: "0.5rem",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: theme.zIndex.appBar,
  },
}));

const MuiSearchTextField: <T>(
  props: TextFieldProps & {
    data: Array<T>;
    nameKey: string;
    selectValue: (value: T) => void;
  }
) => JSX.Element = ({
  data,
  nameKey,
  type,
  autoComplete,
  selectValue,
  ...rest
}) => {
  const [isSearchListShown, setSearchListShown] = useState<boolean>(false);

  useEffect(() => {
    if (!!data.length) {
      console.log("data");
      setSearchListShown(true);
      return;
    }

    setSearchListShown(false);
  }, [data]);

  return (
    <>
      {!!isSearchListShown && (
        <Backdrop
          onClick={() => setSearchListShown(false)}
          className="transparent"
          open={true}
        />
      )}
      <StyledWarapper>
        <MuiTextField
          type={type || "search"}
          autoComplete={autoComplete || "false"}
          // value={selectedItem ? (typeof selectedItem === 'object' ? selectedItem[nameKey] : selectedItem) : ''}
          {...rest}
        />
        {!!isSearchListShown && (
          <div className="list-part">
            <MenuList>
              {data.map((d, index) => (
                <MuiMenuItem
                  key={index}
                  onClick={() => {
                    selectValue(d);
                    setSearchListShown(false);
                  }}
                >
                  {typeof d === "object"
                    ? (d as Record<string, any>)[nameKey]
                    : d}
                </MuiMenuItem>
              ))}
            </MenuList>
          </div>
        )}
      </StyledWarapper>
    </>
  );
};

export default MuiSearchTextField;
