import {
  AppointmentIcon,
  AssessmentIcon,
  NotebookIcon,
  ReportIcon,
  TherapistIcon,
  SetCalenderIcon,
  MeetingIcon,
} from "src/Components/Icons";
import AppointmentsIcon from "src/Components/Icons/AppointmentsIcon";
import AssessmentsIcon from "src/Components/Icons/AssessmentsIcon";
import BookingIcon from "src/Components/Icons/BookingIcon";
import CalendarIcon from "src/Components/Icons/CalendarIcon";
import ClassesIcon from "src/Components/Icons/ClassesIcon";
import ClientIcon from "src/Components/Icons/ClientIcon";
import MasterIcon from "src/Components/Icons/MasterIcon";
import MeetingsIcon from "src/Components/Icons/MeetingsIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import type { RoleModel } from "src/Models/Roles.models";

export class Menu {
  public title!: string;
  public pathname?: string;
  public children?: Array<Menu>;

  // public icon?: (props: SvgIconProps) => void;
  // eslint-disable-next-line no-undef
  [key: string]: any;
}

export const HeaderMenu: Array<Menu> = [
  { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
  { title: "Notes", pathname: "/notes", icon: NotebookIcon },
];

//  Placing first in the Sequence
const commonInitialMenuItems: Menu[] = [
  {
    title: "Clients",
    pathname: "/clients",
    icon: ClientIcon,
  },
];

// Placing last in the Sequence
const commomFinalMenuItems: Menu[] = [
  { title: "Meeting", pathname: "/meeting", icon: MeetingsIcon },
  { title: "Assessments", pathname: "/assessments", icon: AssessmentsIcon },
];

export const menuItems: { [key in RoleModel]: Menu[] } = {
  admin: [
    ...commonInitialMenuItems,
    { title: "Calendar", pathname: "/calendar", icon: CalendarIcon },
    // { title: "Therapists", pathname: "/therapists", icon: TherapistIcon },
    {
      title: "Appointments",
      pathname: "",
      icon: AppointmentsIcon,
      children: [
        { title: "Master", pathname: "/appointments/master", icon: MasterIcon },
        { title: "Booking", pathname: "/appointments/bookings", icon: BookingIcon },
        // { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
        // { title: "Assessments Report", pathname: "/assessments-report", icon: ReportIcon },
      ],
    },
    {
      title: "Classes",
      pathname: "",
      icon: ClassesIcon,
      children: [
        { title: "Master", pathname: "/classes/master", icon: MasterIcon },
        { title: "Booking", pathname: "/classes/booking", icon: BookingIcon },
      ],
    },
    { title: "Seetings", pathname: "/seetings", icon: SettingsOutlinedIcon },
    ...commomFinalMenuItems,
    {
      title: "Pricing",
      pathname: "",
      icon: ClassesIcon,
      children: [
        { title: "Pricing Master", pathname: "/pricing", icon: MasterIcon },
        { title: "sell Pricing", pathname: "/sell-pricing", icon: BookingIcon },
        { title: "View Pricing Sales", pathname: "/view-pricing-sales", icon: SettingsOutlinedIcon },
      ],
    },
    // { title: "Pricing", pathname: "/pricing", icon: SettingsOutlinedIcon },
  ],
  therapist: [
    ...commonInitialMenuItems,
    // { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
    // { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
    {
      title: "Calendar",
      pathname: "",
      icon: SetCalenderIcon,
      children: [
        { title: "Meeting", pathname: "/meeting", icon: MeetingIcon },
        {
          title: "Appointments",
          pathname: "/appointments",
          icon: AppointmentIcon,
        },
        {
          title: "Assessments",
          pathname: "/assessments",
          icon: AssessmentIcon,
        },
        {
          title: "Assessments Report",
          pathname: "/assessments-report",
          icon: ReportIcon,
        },
      ],
    },
    // { title: "Assessments Notes", pathname: "/assessments-notes-list", icon: NotebookIcon },
    { title: "Reports", pathname: "/reports", icon: ReportIcon },
    ...commomFinalMenuItems,
    // Add therapist-specific menu items here if needed
  ],
  director: [
    ...commonInitialMenuItems,
    { title: "Therapists", pathname: "/therapists", icon: TherapistIcon },
    ...commomFinalMenuItems,
    // Add director-specific menu items here if needed
  ],
  pta: [
    ...commonInitialMenuItems,
    // { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
    // { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
    // { title: "Assessments Notes", pathname: "/assessments-notes-list", icon: NotebookIcon },
    {
      title: "Calendar",
      pathname: "",
      icon: SetCalenderIcon,
      children: [
        { title: "Meeting", pathname: "/meeting", icon: MeetingIcon },
        {
          title: "Appointments",
          pathname: "/appointments",
          icon: AppointmentIcon,
        },
        // { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
        // { title: "Assessments Report", pathname: "/assessments-report", icon: ReportIcon },
      ],
    },
    { title: "Reports", pathname: "/reports", icon: ReportIcon },
    ...commomFinalMenuItems,
    // Add pta-specific menu items here if needed
  ],
};
