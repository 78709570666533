import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function AssessmentsIcon({
  width = 21,
  height = 21,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.secondary.main;
  return (
    <SVGContainer {...restProps}>
      <svg 
        width={width} 
        height={height} 
        viewBox="0 0 64 64" 
        xmlns="http://www.w3.org/2000/svg" 
        strokeWidth="3" 
        stroke={color} 
        fill="none"
      >
        <rect x="11.83" y="38.02" width="7" height="11.45"/>
        <rect x="24.83" y="23.12" width="7" height="26.35"/>
        <rect x="37.83" y="28.2" width="7" height="21.27"/>
        <rect x="50.83" y="13.08" width="7" height="36.4"/>
        <polyline points="5.76 6.78 5.76 55.64 61.7 55.64"/>
      </svg>
    </SVGContainer>
  );
}

export default AssessmentsIcon;
