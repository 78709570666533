import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function MasterIcon({
    width = 22,
    height = 22,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.secondary.main;
    return (
        <SVGContainer {...restProps}>
            <svg width={width} height={height} color={color} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8.5H9M10.5 5.5V19.5M6 11.5H9M13 8.5H18M6 14.5H9M4.5 5.5H20.5V19.5H4.5V5.5Z" stroke="currentColor" strokeWidth="1.2" />
            </svg>
        </SVGContainer>
    );
}

export default MasterIcon;