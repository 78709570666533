import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from "../Components";

const Profile = loadable(() => import("../Views/Admin/Profile"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const DashBoard = loadable(() => import("../Views/Admin/DashBoard"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const Notes = loadable(() => import("../Views/Admin/Notes/Notes"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Calendars = loadable(() => import("../Views/Admin/Calendars/Calendars"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Clients = loadable(() => import("../Views/Admin/CaseLoads/Clients"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const AppointmentsMaster = loadable(
  () =>
    import("../Views/Admin/Appointments/AppointmentsMaster/AppointmentsMaster"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const AppointmentsBooking = loadable(
  () =>
    import("../Views/Admin/Appointments/AppointmentsBooking/AppointmentsBooking"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ClassesMaster = loadable(
  () => import("../Views/Admin/Classes/ClassesMaster/ClassesMaster"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ClassesBooking = loadable(
  () => import("../Views/Admin/Classes/ClassesBooking/ClassesBooking"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const Pricing = loadable(() => import("../Views/Admin/Pricing/Pricing"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const AddPricing = loadable(() => import("../Views/Admin/Pricing/AddPricing"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const EditPricing = loadable(() => import("../Views/Admin/Pricing/EditPricing"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const SellPricing = loadable(() => import("../Views/Admin/SellPricing/SellPricing"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const ViewPricingSales = loadable(() => import("../Views/Admin/ViewPricingSales/ViewPricingSales"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const DirectorList = loadable(() => import("../Views/Admin/DirectorList"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const TherapistList = loadable(() => import("../Views/Admin/TherapistList"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const Reports = loadable(() => import("../Views/Admin/Reports"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const Notifications = loadable(() => import("../Views/Admin/Notifications"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Appointments = loadable(
  () => import("../Views/Admin/Appointment/Appointments"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const NotePage = loadable(() => import("../Views/Admin/Notes/NotePage"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const StudentGroup = loadable(() => import("../Views/Admin/StudentGroup"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const StudentProfile = loadable(
  () => import("../Views/Users/StudentDetails/StudentProfile"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ImportStudent = loadable(
  () => import("../Views/Admin/CaseLoads/ImportStudent"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ServiceLog = loadable(
  () => import("../Views/Admin/ServiceLog/ServiceLog"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);
const Assessments = loadable(
  () => import("../Views/Admin/Assesments/Assesments"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const AssessmentNote = loadable(
  () => import("../Views/Admin/Notes/AssesmentsNote"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const AssessmentNoteList = loadable(
  () => import("../Views/Admin/Assesments/AssessmentsReportList"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);
const AssignStudent = loadable(() => import("../Views/Admin/AssignStudent"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Meetings = loadable(() => import("../Views/Admin/Meetings/Meeting"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Timesheet = loadable(() => import("../Views/Admin/Timesheet/Timesheet"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const InvoiceGenerator = loadable(
  () => import("../Views/Admin/Invoice/InvoiceGenerator/InvoiceGenerator"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const AdminInvoiceSettings = loadable(
  () => import("../Views/Admin/Invoice/Settings/InvoiceSettings"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);
const Invoice = loadable(() => import("../Views/Admin/Invoice/InvoiceList"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Seetings = loadable(() => import("../Views/Admin/Seetings/Seetings"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const CategoryService = loadable(
  () => import("../Views/Admin/Seetings/Category"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const HolidayBusinessHours = loadable(
  () => import("../Views/Admin/Seetings/HolidayBusinessHours/HolidayBusinessHours"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const TimezoneCurrency = loadable(
  () => import("../Views/Admin/Seetings/TimezoneCurrency"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const BusinessLocations = loadable(
  () => import("../Views/Admin/Seetings/BusinessLocations"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const Waivers = loadable(
  () => import("../Views/Admin/Seetings/Waivers"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ClientType = loadable(
  () => import("../Views/Admin/Seetings/ClientType"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

export interface RouteInterface {
  path: string;
  exact?: boolean;
  name: string;
  component: ComponentType;
}

export const AdminRoutes: Array<RouteInterface> = [
  { path: "/admin/home", exact: true, name: "DashBoard", component: DashBoard },
  { path: "/admin/notes", exact: true, name: "Notes", component: Notes },
  {
    path: "/admin/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  {
    path: "/admin/calendar",
    exact: true,
    name: "Calendars",
    component: Calendars as any,
  },
  {
    path: "/admin/clients",
    exact: true,
    name: "Clients",
    component: Clients,
  },

  {
    path: "/admin/appointments/master",
    exact: true,
    name: "AppointmentsMaster",
    component: AppointmentsMaster,
  },

  {
    path: "/admin/appointments/bookings",
    exact: true,
    name: "AppointmentsBooking",
    component: AppointmentsBooking,
  },

  {
    path: "/admin/classes/master",
    exact: true,
    name: "ClassesMaster",
    component: ClassesMaster,
  },

  {
    path: "/admin/classes/booking",
    exact: true,
    name: "ClassesBooking",
    component: ClassesBooking,
  },

  {
    path: "/admin/pricing",
    exact: true,
    name: "Pricing",
    component: Pricing,
  },

  {
    path: "/admin/pricing/add",
    exact: true,
    name: "AddPricing",
    component: AddPricing,
  },

  {
    path: "/admin/pricing/edit",
    exact: true,
    name: "EditPricing",
    component: EditPricing,
  },

  {
    path: "/admin/sell-pricing",
    exact: true,
    name: "SellPricing",
    component: SellPricing,
  },

  {
    path: "/admin/view-pricing-sales",
    exact: true,
    name: "ViewPricingSales",
    component: ViewPricingSales,
  },

  {
    path: "/admin/students/profile",
    exact: true,
    name: "StudentProfile",
    component: StudentProfile,
  },

  {
    path: "/admin/directors",
    exact: true,
    name: "DirectorList",
    component: DirectorList,
  },

  {
    path: "/admin/group",
    exact: true,
    name: "StudentGroup",
    component: StudentGroup,
  },
  {
    path: "/admin/therapists",
    exact: true,
    name: "TherapistList",
    component: TherapistList,
  },
  {
    path: "/admin/reports",
    exact: true,
    name: "Reports",
    component: Reports,
  },
  {
    path: "/admin/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/admin/notifications",
    exact: true,
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/admin/appointments",
    exact: true,
    name: "Appointments",
    component: Appointments,
  },
  {
    path: "/admin/create-note",
    exact: true,
    name: "NotePage",
    component: NotePage,
  },

  {
    path: "/admin/student/import-students",
    exact: true,
    name: "ImportStudent",
    component: ImportStudent,
  },
  {
    path: "/admin/service-log",
    exact: true,
    name: "ServiceLog",
    component: ServiceLog,
  },
  {
    path: "/admin/assessments",
    exact: true,
    name: "Assessments",
    component: Assessments,
  },
  {
    path: "/admin/assessments-note",
    exact: true,
    name: "AssessmentNote",
    component: AssessmentNote,
  },
  {
    path: "/admin/assessments-report",
    exact: true,
    name: "AssessmentNoteList",
    component: AssessmentNoteList,
  },
  {
    path: "/admin/assign-student",
    exact: true,
    name: "AssignStudent",
    component: AssignStudent,
  },

  {
    path: "/admin/meeting",
    exact: true,
    name: "Meetings",
    component: Meetings,
  },
  {
    path: "/admin/timesheet",
    exact: true,
    name: "Timesheet",
    component: Timesheet,
  },
  {
    path: "/admin/generate-invoice",
    exact: true,
    name: "InvoiceGenerator",
    component: InvoiceGenerator,
  },
  {
    path: "/admin/invoice-setting",
    exact: true,
    name: "AdminInvoiceSettings",
    component: AdminInvoiceSettings,
  },
  {
    path: "/admin/invoices",
    exact: true,
    name: "Invoices",
    component: Invoice,
  },
  {
    path: "/admin/seetings",
    exact: true,
    name: "Categories",
    component: Seetings,
  },
  {
    path: "/admin/seetings/category-service",
    exact: true,
    name: "Categories",
    component: CategoryService,
  },
  {
    path: "/admin/seetings/holiday-business-hrs",
    exact: true,
    name: "HolidayBusinessHours",
    component: HolidayBusinessHours,
  },
  {
    path: "/admin/seetings/timezone-currency",
    exact: true,
    name: "TimezoneCurrency",
    component: TimezoneCurrency,
  },
  {
    path: "/admin/seetings/business-locations",
    exact: true,
    name: "BusinessLocations",
    component: BusinessLocations,
  },
  {
    path: "/admin/seetings/waivers",
    exact: true,
    name: "Waivers",
    component: Waivers,
  },
  {
    path: "/admin/seetings/client-type",
    exact: true,
    name: "ClientType",
    component: ClientType,
  },
];
