import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function AppointmentsIcon({
    width = 22,
    height = 22,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.secondary.main;
    return (
        <SVGContainer {...restProps}>
            <svg 
                fill={color} 
                width={width} 
                height={height} 
                viewBox="0 0 22 22" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M18,5V3a1,1,0,0,0-2,0V5H8V3A1,1,0,0,0,6,3V5H2V21H22V5Zm2,14H4V7H20ZM9,10H7v2H9Zm0,4H7v2H9Zm8-4H11v2h6Zm0,4H11v2h6Z"/>
            </svg>
        </SVGContainer>
    );
}

export default AppointmentsIcon;