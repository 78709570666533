import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function BookingIcon({
    width = 22,
    height = 22,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.secondary.main;
    return (
        <SVGContainer {...restProps}>
      <svg width={width} height={height} color={color} viewBox="0 0 1024 1024" className="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M484 1008.8H480c-33.6-1.6-63.2-25.6-72-59.2-10.4-40 10.4-80.8 49.6-95.2 8.8-3.2 18.4-4.8 27.2-4.8 30.4 0 58.4 17.6 71.2 44.8 3.2 6.4 7.2 7.2 9.6 7.2h3.2c135.2-22.4 235.2-96 297.6-218.4 0.8-0.8 0.8-2.4 1.6-4l2.4-6.4-7.2 0.8-4.8 0.8c-3.2 0-4.8 0.8-7.2 0.8h-6.4c-59.2 0-107.2-48-107.2-107.2V457.6c0-59.2 48-107.2 107.2-107.2h6.4c7.2 0 13.6 2.4 20.8 4 3.2 0.8 7.2 1.6 10.4 2.4l2.4 0.8 6.4 2.4-1.6-7.2C856 212 718.4 68 522.4 63.2H512c-200 0-341.6 144.8-378.4 288l-1.6 6.4 6.4-1.6c2.4-0.8 4.8-0.8 7.2-1.6 5.6-1.6 10.4-3.2 16-4 5.6-0.8 12-1.6 17.6-1.6 50.4 0 96.8 38.4 104.8 88 0.8 6.4 1.6 14.4 1.6 22.4v108c0 52-34.4 94.4-85.6 105.6-7.2 1.6-14.4 2.4-21.6 2.4-52 0-96.8-38.4-105.6-88.8-0.8-7.2-1.6-12.8-1.6-18.4v-32c0-28-0.8-56.8 0-84.8 3.2-140.8 61.6-256.8 172-344 55.2-44 120-72 192-84.8 35.2-6.4 72-8.8 107.2-5.6 5.6 0.8 12 0.8 17.6 1.6 62.4 6.4 120.8 25.6 172.8 56.8 104.8 61.6 173.6 152.8 205.6 269.6 9.6 34.4 14.4 71.2 14.4 109.6V567.2c0 16.8-4 32.8-11.2 49.6-2.4 4.8-4 10.4-4.8 16-0.8 1.6-0.8 4-1.6 5.6-46.4 156-174.4 273.6-333.6 306.4-9.6 1.6-20 4-32.8 5.6-6.4 0.8-8 4.8-9.6 8-12.8 29.6-41.6 50.4-75.2 50.4z m362.4-612h-6.4c-31.2 3.2-53.6 28.8-53.6 59.2V567.2c0 4.8 0.8 9.6 1.6 14.4 6.4 27.2 30.4 46.4 57.6 46.4 2.4 0 4.8 0 7.2-0.8 31.2-4 53.6-28.8 53.6-59.2V456.8c0-4.8-0.8-9.6-1.6-13.6-7.2-27.2-31.2-46.4-58.4-46.4z m-668 0c-33.6 0-59.2 26.4-59.2 60v109.6c0 4.8 0.8 9.6 1.6 13.6 6.4 27.2 30.4 46.4 57.6 46.4h6.4c31.2-3.2 53.6-28.8 53.6-59.2V456.8c-0.8-33.6-26.4-60-60-60z" fill='currentColor' /></svg>
    </SVGContainer>
    );
}

export default BookingIcon;