import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function MeetingsIcon({
    width = 22,
    height = 22,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.secondary.main;
    return (
        <SVGContainer {...restProps}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 48 48"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <title>ic_fluent_conference_room_48_regular</title>
                <desc>Created with Sketch.</desc>
                <g id="🔍-Product-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="ic_fluent_conference_room_48_regular" fill={color} fillRule="nonzero">
                        <path
                            d="M22.503257,7.77592448 L37.003257,10.7759245 C37.5837049,10.8960171 38,11.4072589 38,12 L38,36 C38,36.5927411 37.5837049,37.1039829 37.003257,37.2240755 L22.503257,40.2240755 C21.7274985,40.3845773 21,39.7921881 21,39 L21,9 C21,8.20781189 21.7274985,7.61542272 22.503257,7.77592448 Z M18,10 L18,12.5 L12.5,12.5 L12.5,35.5 L18,35.5 L18,38 L11.25,38 C10.6027913,38 10.0704661,37.5081253 10.0064536,36.8778052 L10,36.75 L10,11.25 C10,10.6027913 10.4918747,10.0704661 11.1221948,10.0064536 L11.25,10 L18,10 Z M23.5,10.5350942 L23.5,37.4649058 L35.5,34.9821472 L35.5,13.0178528 L23.5,10.5350942 Z M26.5,22.5 C27.3284271,22.5 28,23.1715729 28,24 C28,24.8284271 27.3284271,25.5 26.5,25.5 C25.6715729,25.5 25,24.8284271 25,24 C25,23.1715729 25.6715729,22.5 26.5,22.5 Z"
                            id="🎨-Color"
                        ></path>
                    </g>
                </g>
            </svg>
        </SVGContainer>
    );
}

export default MeetingsIcon;