import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
    width?: number;
    height?: number;
    iconColor?: string;
}

function ClassesIcon({
    width = 20,
    height = 20,
    iconColor,
    ...restProps
}: SvgProps) {
    const theme = useTheme();

    const color = iconColor || theme.palette.secondary.main;
    return (
        <SVGContainer {...restProps}>
            <svg width={width} height={height} color={color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M22 17a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2h-2v2h2v-2zm-7-2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2h-2v2h2v-2zm-7-2a1 1 0 011 1v4a1 1 0 01-1 1H2a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2H3v2h2v-2zM22 9a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2h-2v2h2v-2zm-7-2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2h-2v2h2v-2zM6 9a1 1 0 011 1v4a1 1 0 01-1 1H2a1 1 0 01-1-1v-4a1 1 0 011-1h4zm-1 2H3v2h2v-2zM22 1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V2a1 1 0 011-1h4zm-1 2h-2v2h2V3zm-7-2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V2a1 1 0 011-1h4zm-1 2h-2v2h2V3zM6 1a1 1 0 011 1v4a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h4zM5 3H3v2h2V3z"/>
            </svg>
        </SVGContainer>
    );
}

export default ClassesIcon;