import React, { useState } from "react";
import { StyledSidebar } from "../styled";
import { Link, NavLink } from "react-router-dom";
import { AngleIcon } from "../../Components/Icons";
import { MuiBox, MuiListItem } from "../../Components/MUI";
import { useSelector } from "react-redux";
import { menuItems } from "./Menu";
import type { RoleModel } from "src/Models/Roles.models";
import { classNames } from "src/utils/helpers";
import Logo from "src/assets/Images/Group 1000003898.svg";
import BigLogo from "src/assets/Images/Group 1000003899.svg";

interface SidebarProps {
  sidebarExpanded?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarExpanded }) => {
  const [showChild, setShowChild] = useState<string>("");
  const { user } = useSelector((state: any) => state.auth);
  console.log("user", user);

  const menu =
    menuItems[
      user?.userHasRole
        .map((item: any) => item.role.name)
        .join(", ") as RoleModel
    ];
  const url =
    (user?.userHasRole
      .map((item: any) => item.role.name)
      .join(", ") as RoleModel) === "admin"
      ? "admin"
      : (user?.userHasRole
          .map((item: any) => item.role.name)
          .join(", ") as RoleModel) === "pta"
      ? "therapist"
      : user?.userHasRole.map((item: any) => item.role.name).join(", ");

  return (
    <StyledSidebar>
      <Link className="logo-part" to={`/${url}${menu[0].pathname}`}>
        {!!sidebarExpanded ? (
          <img src={BigLogo} alt="logo" width="100%" height="100%" />
        ) : (
          <img src={Logo} alt="logo" width="100%" height="100%" />
        )}
      </Link>

      <MuiBox className="side-items-sec">
        {menu.map((item, index) => (
          <MuiBox className="sideBar-items" key={index}>
            <MuiListItem>
              <NavLink
                onClick={() => {
                  if (!!item.children) {
                    setShowChild((prev) => {
                      if (prev === item.title.replaceAll(" ", "_")) {
                        return "";
                      }
                      return item.title.replaceAll(" ", "_");
                    });
                  } else {
                    // Close the parent menu if it's open
                    setShowChild("");
                  }
                }}
                to={!item.children ? `/${url}${item.pathname}` : "#"}
                style={{ display: "block", width: "100%" }}
                className={(navData) =>
                  classNames("item-btn", {
                    active: !!item.pathname && !!navData.isActive,
                    link: !navData.isActive,
                  })
                }
              >
                <MuiBox className="item-btn justify-content-between">
                  <MuiBox className="d-flex align-items-center">
                    <MuiBox className="btn-icon">
                      <item.icon />
                    </MuiBox>
                    <MuiBox className="btn-txt">{item.title}</MuiBox>
                  </MuiBox>

                  {!!item.children?.length && (
                    <MuiBox className="btn-icon with-children">
                      <AngleIcon />
                    </MuiBox>
                  )}
                </MuiBox>
                <MuiBox
                  className={classNames("child-menu", {
                    show: showChild === item.title.replaceAll(" ", "_"),
                  })}
                >
                  {item.children?.map((subItem, index) => (
                    <React.Fragment key={index}>
                      <MuiListItem>
                        <NavLink
                          to={`/${url}${subItem.pathname}`}
                          style={{ display: "block", width: "100%" }}
                          className={(navData) =>
                            classNames("item-btn", {
                              active: !!navData.isActive,
                              link: !navData.isActive,
                            })
                          }
                          onClick={() => {
                            setShowChild("");
                          }}
                        >
                          <MuiBox className="item-btn">
                            <MuiBox className="btn-icon">
                              <subItem.icon />
                            </MuiBox>
                            <MuiBox className="btn-txt">{subItem.title}</MuiBox>
                          </MuiBox>
                        </NavLink>
                      </MuiListItem>
                    </React.Fragment>
                  ))}
                </MuiBox>
              </NavLink>
            </MuiListItem>
          </MuiBox>
        ))}
      </MuiBox>
    </StyledSidebar>
  );
};

export default Sidebar;
