import SVGContainer from "./SVGContainer";

function TableSortingDisabledArrowIcon({ width = 10, height = 16, ...restProps }) {
   const color = '#64748B';
   return (
      <SVGContainer {...restProps}>
         <svg width={width} height={height} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 0L9.33013 6.75H0.669873L5 0Z" fill={color} fillOpacity="0.49" />
            <path d="M5 16L0.669872 9.25L9.33013 9.25L5 16Z" fill={color} fillOpacity="0.49" />
         </svg>
      </SVGContainer>
   );
}

export default TableSortingDisabledArrowIcon;
