import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function MoreIcon({
  width = 33,
  height = 7,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || theme.palette.primary.main;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 33 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 3.5C7 5.433 5.433 7 3.5 7C1.567 7 0 5.433 0 3.5C0 1.567 1.567 0 3.5 0C5.433 0 7 1.567 7 3.5Z"
          fill="#83909A"
        />
        <path
          d="M20 3.5C20 5.433 18.433 7 16.5 7C14.567 7 13 5.433 13 3.5C13 1.567 14.567 0 16.5 0C18.433 0 20 1.567 20 3.5Z"
          fill="#83909A"
        />
        <path
          d="M33 3.5C33 5.433 31.433 7 29.5 7C27.567 7 26 5.433 26 3.5C26 1.567 27.567 0 29.5 0C31.433 0 33 1.567 33 3.5Z"
          fill="#83909A"
        />
      </svg>
    </SVGContainer>
  );
}

export default MoreIcon;
