import React from "react";
import {
  MuiBox,
  MuiIconButton,
  MuiListItem,
  MuiMenuItem,
  MuiTypography,
} from "../../Components/MUI";
import { StyledBadge, StyledHeader, StyledMenu } from "../styled";
import {
  BellIcon,
  ProfileAvater,
  ToggleMenuIcon,
} from "../../Components/Icons";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "src/Redux/features/common/auth/authSlice";
import { HeaderMenu } from "./Menu";
import { classNames } from "src/utils/helpers";

interface HeaderProps {
  handleMenu: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  toggleMenu: () => void;
  sidebarExpanded: boolean;
}

const Header: React.FC<HeaderProps> = ({
  handleMenu,
  toggleMenu,
  anchorEl,
  handleClose,
  sidebarExpanded,
}) => {
  const dispatch = useDispatch(); // Create a dispatch function
  const { user } = useSelector((state: any) => state.auth);

  const handleLogout = () => {
    dispatch(logOut()); // Dispatch the logOut action
  };

  return (
    <>
      <StyledHeader
        className={classNames("", { "with-sidebar-expanded": sidebarExpanded })}
      >
        <MuiBox
          className="res-sidebar-toggle d-flex align-items-center justify-content-center ml-3"
          onClick={toggleMenu}
        >
          <ToggleMenuIcon iconColor="white" />
        </MuiBox>
        <MuiBox className="container">
          <MuiBox className="d-flex align-items-center">
            <MuiBox className="d-flex">
              {user.role === "therapist" &&
                HeaderMenu.map(
                  (item, index) =>
                    item.pathname && (
                      <MuiBox key={index} className="header-items">
                        <NavLink
                          to={`/therapist${item.pathname}`}
                          className={(navData) =>
                            navData.isActive ? "active" : "link"
                          }
                        >
                          <MuiListItem className="item-btn">
                            <MuiBox className="btn-txt">{item.title}</MuiBox>
                          </MuiListItem>
                        </NavLink>
                      </MuiBox>
                    )
                )}
              {user.role === "pta" &&
                HeaderMenu.map(
                  (item, index) =>
                    item.pathname && (
                      <MuiBox key={index} className="header-items">
                        <NavLink
                          to={`/therapist${item.pathname}`}
                          className={(navData) =>
                            navData.isActive ? "active" : "link"
                          }
                        >
                          <MuiListItem className="item-btn">
                            <MuiBox className="btn-txt">{item.title}</MuiBox>
                          </MuiListItem>
                        </NavLink>
                      </MuiBox>
                    )
                )}
              {user.role === "superadmin" &&
                HeaderMenu.map(
                  (item, index) =>
                    item.pathname && (
                      <MuiBox key={index} className="header-items">
                        <NavLink
                          to={`/admin${item.pathname}`}
                          className={(navData) =>
                            navData.isActive ? "active" : "link"
                          }
                        >
                          <MuiListItem className="item-btn">
                            <MuiBox className="btn-txt">{item.title}</MuiBox>
                          </MuiListItem>
                        </NavLink>
                      </MuiBox>
                    )
                )}
            </MuiBox>
          </MuiBox>

          <MuiBox className="d-flex user-container ">
            <MuiBox className="d-flex align-items-center">
              <MuiBox className="d-flex align-items-center mr-3">
                <MuiBox className="welcome mr-4">
                  <MuiTypography variant="h6" className=" p-1">
                    Welcome,
                  </MuiTypography>
                  <MuiTypography variant="h6" className=" py-1 pr-1">
                    {user?.name}
                  </MuiTypography>
                </MuiBox>

                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <BellIcon iconColor="white" />
                </StyledBadge>
              </MuiBox>

              <MuiBox className="d-flex align-items-center">
                <MuiIconButton onClick={handleMenu}>
                  <ProfileAvater iconColor="white" />{" "}
                </MuiIconButton>

                <StyledMenu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <NavLink
                    to={
                      user?.role === "superadmin"
                        ? "/admin/profile"
                        : user?.role === "director"
                        ? "/director/profile"
                        : "/therapist/profile"
                    }
                    className="navlink"
                    onClick={handleClose}
                  >
                    <MuiMenuItem>Profile</MuiMenuItem>
                  </NavLink>
                  <MuiMenuItem onClick={handleLogout}>Log out</MuiMenuItem>
                </StyledMenu>
              </MuiBox>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </StyledHeader>
    </>
  );
};

export default Header;
