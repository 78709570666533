import React, { useState } from "react";
import { AuthContainer, AuthStyle } from "../styled";
import { Outlet } from "react-router-dom";
import { LightLogoIcon } from "../../Components/Icons";
import { MuiCardHeader, MuiCardContent, MuiBox } from "../../Components/MUI";

type Title = string | null;
type Subtitle = string | null;

const AuthLayout: React.FC = () => {
  const [title, setTitle] = useState<Title>(null);
  const [subtitle, setSubtitle] = useState<Subtitle>(null);

  const changeTitle = (newTitle: Title) => {
    setTitle(newTitle);
  };

  const changeSubtitle = (newSubtitle: Subtitle) => {
    setSubtitle(newSubtitle);
  };
  return (
    <AuthStyle className="min-100vh d-flex justify-content-center align-items-center">
      <MuiBox className="container d-flex justify-content-center my-5">
        <AuthContainer>
          <MuiBox className="d-flex justify-content-center align-items-center mt-5 mb-4">
            <LightLogoIcon />
          </MuiBox>

          <MuiCardHeader
            className="text-center "
            title={title || ""}
            subheader={subtitle || ""}
            subheaderTypographyProps={{ mt: 2 }}
          />
          <MuiCardContent className="my-3">
            <Outlet context={[changeTitle, changeSubtitle]} />
          </MuiCardContent>
        </AuthContainer>
      </MuiBox>
    </AuthStyle>
  );
};

export default AuthLayout;
