import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from "../Components";

// const DashBoard = loadable(() => import("../Views/Users/DashBoard"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const SchoolDistrictList = loadable(() => import("../Views/Users/CaseLoads/SchoolDistrictList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const SchoolList = loadable(() => import("../Views/Users/CaseLoads/SchoolList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const StudentList = loadable(() => import("../Views/Users/CaseLoads/StudentList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
const Profile = loadable(() => import("../Views/Users/Profile"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
// const StudentSOAP = loadable(() => import("../Views/Users/StudentSOAP"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
const StudentProfile = loadable(() => import("../Views/Users/StudentDetails/StudentProfile"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
const CaseLoad = loadable(() => import("../Views/Users/CaseLoads/CaseLoads"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});


const Reports = loadable(() => import("../Views/Admin/Reports"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
const Notes = loadable(() => import("../Views/Admin/Notes/Notes"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
const CreateNotes = loadable(() => import("../Views/Admin/Notes/CreateNote"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});

const Appointments = loadable(() => import("../Views/Admin/Appointment/Appointments"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});

const Assessments = loadable(() => import("../Views/Admin/Notes/NotePage"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
const StudentGroup = loadable(() => import("../Views/Admin/StudentGroup"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});
const ServiceLog = loadable(() => import("../Views/Admin/ServiceLog/ServiceLog"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});

const ImportStudent = loadable(() => import("../Views/Admin/CaseLoads/ImportStudent"), {
  fallback: <Fallback subtitle="Loading data..."/>,
});

const Assesments = loadable(() => import("../Views/Admin/Assesments/Assesments"), {
  fallback: <Fallback subtitle="Loading data..."/>
});

const AssesmentNote = loadable(() => import("../Views/Admin/Notes/AssesmentsNote"), {
  fallback: <Fallback subtitle="Loading data..."/>
});

const AssesmentNoteList = loadable(() => import("../Views/Admin/Assesments/AssessmentsReportList"), {
  fallback: <Fallback subtitle="Loading data..."/>
});

const Meetings = loadable(() => import("../Views/Admin/Meetings/Meeting"), {
  fallback: <Fallback subtitle="Loading data..."/>
});

export interface RouteInterface {
  path: string;
  exact?: boolean;
  name: string;
  component: ComponentType;
}

export const TherapistRoutes: Array<RouteInterface> = [
  // { path: "/therapist/home", exact: true, name: "DashBoard", component: DashBoard },
  {
    path: "/therapist/caseload",
    exact: true,
    name: "Case Load",
    component: CaseLoad,
  },
  // {
  //   path: "/therapist/schools-districts",
  //   exact: true,
  //   name: "SchoolDistrictList",
  //   component: SchoolDistrictList,
  // },
  // {
  //   path: "/therapist/schools",
  //   exact: true,
  //   name: "SchoolList",
  //   component: SchoolList,
  // },
  // {
  //   path: "/therapist/students",
  //   exact: true,
  //   name: "StudentList",
  //   component: StudentList,
  // },
  {
    path: "/therapist/students/profile",
    exact: true,
    name: "StudentProfile",
    component: StudentProfile,
  },
  // {
  //   path: "/therapist/students/SOAP",
  //   exact: true,
  //   name: "StudentSOAP",
  //   component: StudentSOAP,
  // },
  {
    path: "/therapist/appointments",
    exact: true,
    name: "Appointments",
    component: Appointments,
  },
  {
    path: "/therapist/create-note",
    exact: true,
    name: "Assessments",
    component: Assessments,
  },
  { path: "/therapist/notes", exact: true, name: "Notes", component: Notes },
  { path: "/therapist/create-notes", exact: true, name: "CreateNotes", component: CreateNotes },
  {
    path: "/therapist/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  {
    path: "/therapist/group",
    exact: true,
    name: "StudentGroup",
    component: StudentGroup,
  },
  {
    path: "/therapist/reports",
    exact: true,
    name: "Reports",
    component: Reports,
  },

  {
    path: "/therapist/service-log",
    exact: true,
    name: "ServiceLog",
    component: ServiceLog,
  },
  {
    path: "/therapist/assessments",
    exact: true,
    name: "Assesments",
    component: Assesments,
  },
  
 
  {
    path: "/therapist/student/import-students",
    exact: true,
    name: "ImportStudent",
    component: ImportStudent,
  },
  {
    path: "/therapist/assessments-note",
    exact: true,
    name: "AssesmentNote",
    component: AssesmentNote,
  },
  {
    path: "/therapist/assessments-report",
    exact: true,
    name: "AssesmentNoteList",
    component: AssesmentNoteList,
  },

  {
    path: "/therapist/meeting",
    exact: true,
    name: "Meetings",
    component: Meetings,
  },
];
