import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from "../Components";

// const DashBoard = loadable(() => import("../Views/Users/DashBoard"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const SchoolDistrictList = loadable(() => import("../Views/Users/CaseLoads/SchoolDistrictList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const SchoolList = loadable(() => import("../Views/Users/CaseLoads/SchoolList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
// const StudentList = loadable(() => import("../Views/Users/CaseLoads/StudentList"), {
//   fallback: <Fallback subtitle="Loading data..."/>,
// });
const Profile = loadable(() => import("../Views/Users/Profile"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const StudentProfile = loadable(() => import("../Views/Users/StudentDetails/StudentProfile"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const CaseLoad = loadable(() => import("../Views/Users/CaseLoads/CaseLoads"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Reports = loadable(() => import("../Views/Admin/Reports"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const Notes = loadable(() => import("../Views/Admin/Notes/Notes"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const Appointments = loadable(
  () => import("../Views/Admin/Appointment/Appointments"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const Assessments = loadable(() => import("../Views/Admin/Notes/NotePage"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const StudentGroup = loadable(() => import("../Views/Admin/StudentGroup"), {
  fallback: <Fallback subtitle="Loading data..." />,
});
const ServiceLog = loadable(
  () => import("../Views/Admin/ServiceLog/ServiceLog"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);

const ImportStudent = loadable(
  () => import("../Views/Admin/CaseLoads/ImportStudent"),
  {
    fallback: <Fallback subtitle="Loading data..." />,
  }
);
const TherapistList = loadable(() => import("../Views/Admin/TherapistList"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

export interface RouteInterface {
  path: string;
  exact?: boolean;
  name: string;
  component: ComponentType;
}

export const DirectorRoutes: Array<RouteInterface> = [
  // { path: "/director/home", exact: true, name: "DashBoard", component: DashBoard },
  {
    path: "/director/caseload",
    exact: true,
    name: "Case Load",
    component: CaseLoad,
  },

  {
    path: "/director/students/profile",
    exact: true,
    name: "StudentProfile",
    component: StudentProfile,
  },
  {
    path: "/director/therapists",
    exact: true,
    name: "TherapistList",
    component: TherapistList,
  },

  //   {
  //     path: "/director/appointments",
  //     exact: true,
  //     name: "Appointments",
  //     component: Appointments,
  //   },
  //   {
  //     path: "/director/create-note",
  //     exact: true,
  //     name: "Assessments",
  //     component: Assessments,
  //   },
  { path: "/director/notes", exact: true, name: "Notes", component: Notes },
  {
    path: "/director/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  //   {
  //     path: "/director/group",
  //     exact: true,
  //     name: "StudentGroup",
  //     component: StudentGroup,
  //   },
  //   {
  //     path: "/director/reports",
  //     exact: true,
  //     name: "Reports",
  //     component: Reports,
  //   },

  {
    path: "/director/service-log",
    exact: true,
    name: "ServiceLog",
    component: ServiceLog,
  },

  {
    path: "/director/student/import-students",
    exact: true,
    name: "ImportStudent",
    component: ImportStudent,
  },
];
