import React, { memo, MouseEvent, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { MuiTypography } from ".";

interface MuiTextFieldProps extends Omit<TextFieldProps, "variant"> {
  Fieldlabel?: string;
  placeholder?: string;
  variant?: "outlined" | "filled" | "standard";
  helperText?: any;
  onInfoClick?: () => void;
  required?: boolean;
}

const MuiTextField: React.FC<MuiTextFieldProps> = memo((props) => {
  const {
    Fieldlabel,
    placeholder,
    type,
    helperText,
    onInfoClick,
    required = false,
    variant = "outlined",
    ...otherProps
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        width: "100%",
        ".MuiInputBase-adornedEnd": {
          paddingRight: 0,
        },

        ".MuiInputAdornment-root ": {
          position: "absolute",
          top: "50%",
          right: "1rem",
          transform: "translateY(-50%)",
        },
      }}
    >
      {Fieldlabel && (
        <Typography
          component="label"
          className="mb-1 ml-1"
          sx={{ display: "block" }}
        >
          {Fieldlabel}
          {required && (
            <MuiTypography color="red" component="span">
              *
            </MuiTypography>
          )}
        </Typography>
      )}
      {type === "password" ? (
        <TextField
          {...otherProps}
          type={showPassword ? "text" : "password"}
          size="small"
          placeholder={placeholder}
          fullWidth
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <RemoveRedEyeOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          {...otherProps}
          type={type}
          placeholder={placeholder}
          fullWidth
          size="small"
          variant={variant}
        />
      )}
    </Box>
  );
});

export default MuiTextField;
